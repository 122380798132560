<template>
  <div>
    <h4 class="mb-4">Sincronização com WhatsApp</h4>
    <div class="mb-base">
      <div>
        <div v-if="whatsapp.exists == false">       
          <b-alert show variant="danger">
            <div class="alert-body">
              <feather-icon
                class="mr-25"
                icon="InfoIcon"
              />
              <span class="ml-25">Você não possui uma instância de WhatsApp configurada.</span>
            </div>
          </b-alert>
        </div>
        <div v-else>
          <div v-if="whatsapp.connected">
            <b-alert show variant="success">
              <div class="alert-body">
                <feather-icon
                  class="mr-25"
                  icon="CheckCircleIcon"
                />
                <span class="ml-25">Sua instância do WhatsApp está conectada. </span>
              </div>
            </b-alert> 
            <b-button class="mt-5" size="sm" variant="relief-danger" @click="disconnectWhatsapp">
              Desconectar
            </b-button>
          </div>
          <div v-else>
            <b-alert show variant="danger">
              <div class="alert-body">
                <feather-icon
                  class="mr-25"
                  icon="InfoIcon"
                />
                <span class="ml-25">Conecte sua instância do WhatsApp e em seguida clique no botão "Atualizar informações".</span>
              </div>
            </b-alert>
            <img style="max-width:230px !important;width:230px !important" v-bind:src="whatsapp.qr_code" />
            <b-button class="ml-5" size="sm" variant="relief-success" @click="refreshWhatsapp">
              Atualizar informações
            </b-button>
          </div>
        </div>
      </div>      
    </div>
  </div>
</template>

<script>

import { BAlert, BButton } from 'bootstrap-vue'
import axios from "@axios"

export default {
  data() {
    return {
      whatsapp: {
        connected: false,
        qr_code: undefined,
        exists: true,
      }
    };
  },
  components: {
    BButton,
    BAlert
  },
  methods: {
    getQrCode() {
      axios
      .get("whatsapp/status")
      .then((res) => {
        if(res.data.message){
          if(res.data.message == 'Desconectado'){
            this.whatsapp.connected = false
            this.whatsapp.exists = false
          }else if(res.data.message == 'Whatsapp Ok'){
            this.whatsapp.connected = true 
          }else{
            this.whatsapp.connected = false
          }
        }else{
          if(res.data.qr){
            this.whatsapp.connected = false
            this.whatsapp.qr_code = res.data.qr
          }else{
            this.whatsapp.connected = true
          }
        }
      })
      .catch(err => {
        console.error(err)
      })     
    },
    disconnectWhatsapp() {
      axios
      .get('whatsapp/desconectar')
      .then((res) => {
        this.whatsapp.connected = false
        this.getQrCode()
      })
      .catch(err => {
        console.error(err)
      })
    },
    refreshWhatsapp() {
      this.getQrCode()
    }
  },
  created() {
    this.getQrCode()
  }
};
</script>

<style>
.img-qr{
  max-width: 150px !important;
  width: 150px !important;
}
</style>